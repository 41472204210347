import { lazyAMapApiLoaderInstance } from 'vue-amap'
import '../../../core/amap'

const mapInit = {
  mounted() {
    if (!window.AMap || !window.AMap.MarkerClusterer) {
      window.AMap = null
      lazyAMapApiLoaderInstance._scriptLoadingPromise = null
    }
    lazyAMapApiLoaderInstance.load().then(() => {
          this.init()
    })
  },
}

export default mapInit

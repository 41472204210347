<template>
  <a-spin :spinning="spinShow">
    <a-row>
      <a-col :span="8" v-if="this.type === 'check'">
        <a-list item-layout="horizontal" bordered :dataSource="userProfileData" style="height: 400px;overflow: scroll">
          <div slot="header" style="font-size: larger;font-weight: bolder">
            <a-icon type="hdd"/>&nbsp;
            已有档案
          </div>
          <a-list-item slot="renderItem" slot-scope="item">
            <a-button slot="actions" type="danger" shape="circle" icon="delete" @click="userProfileDelete(item.id)"/>
            <a-list-item-meta>
              <a-avatar slot="avatar"
                        :style="{ background: item.type === spotTypeEnum.JIWUCHI.value? '#32a27c' : item.type === spotTypeEnum.SANGE.value ? '#175da2' : '#a29a31'}">
                {{ item.type === spotTypeEnum.JIWUCHI.value ? '集' : item.type === spotTypeEnum.SANGE.value ? '化' : '单' }}
              </a-avatar>
              <div slot="title">

                <a-icon style="color:#2ba245;font-size: 18px;margin-right: 6px" v-if=" item.collectType === 'WECHAT' " type="wechat" theme="filled"/>
                <a-icon style="color:#2b97ff;font-size: 18px;margin-right: 6px" v-if=" item.collectType === 'REGISTER' " type="phone" theme="filled"/>
                <a-icon style="color:#5007ff;font-size: 18px;margin-right: 6px" v-if=" item.collectType === 'IMPORT' " type="import"/>

                &nbsp;<a-icon type="swap"
                              style="font-size: 14px"/>&nbsp;{{ item.userName }}
                <a-divider type="vertical"/>
                <a-icon type="phone"/>&nbsp;{{ item.userPhone }}
              </div>
              <div slot="description">
                <a-icon type="environment"/>
                {{ item.areaName }}
                <a-divider type="vertical"/>
                {{ item.location }}
              </div>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </a-col>
      <a-col :span="this.type==='check' ? 16 : 24">
        <a-form :form="form">
          <a-row>
            <a-col :span="12" v-show="type==='edit'">
              <a-form-item label="编号" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['no']" disabled placeholder="编号">
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="名称" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['name', { rules: [{ required: true, message: '名称不能为空' }] }]" placeholder="请输入名称">
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="类型" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-select
                  v-decorator="['type', {initialValue: 'SANGE', rules: [{ required: true, message: '请选择类型' }] }]"
                  @change="typeOnChange">
                  <a-select-option :value="spotTypeEnum.DANWENG.value">单翁</a-select-option>
                  <a-select-option :value="spotTypeEnum.JIWUCHI.value">集污池</a-select-option>
                  <a-select-option :value="spotTypeEnum.SANGE.value">三格化粪池</a-select-option>
                  <a-select-option :value="spotTypeEnum.LANI.value">拉泥</a-select-option>
                  <a-select-option :value="spotTypeEnum.GONGCE.value">公厕</a-select-option>
                  <a-select-option :value="spotTypeEnum.OTHER.value">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="容积" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['bulk', { initialValue: 2,rules: [{ required: true, message: '容积不能为空' }] }]"
                         addonAfter="m³"
                         placeholder="请输入容积">
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="内外" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-radio-group
                  v-decorator="['positionType', {initialValue: 'nei', rules: [{ required: true, message: '请选择类型' }] }]"
                  defaultValue="院内">
                  <a-radio value="nei">
                    院内
                  </a-radio>
                  <a-radio value="wai">
                    院外
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="采集" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-radio-group
                  v-decorator="['collectType', {initialValue: 'REGISTER', rules: [{ required: true, message: '请选择类型' }] }]"
                  defaultValue="登记">
                  <a-radio value="REGISTER">
                    登记
                  </a-radio>
                  <a-radio value="WECHAT">
                    微信
                  </a-radio>
                  <a-radio value="IMPORT">
                    导入
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="区域" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-cascader :options="areaOptions"
                            :defaultValue="areaDefaultValue"
                            v-model="areaDefaultValue"
                            placeholder="选择区域" @change="areaOnChange">
                </a-cascader>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="详细位置" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input-search
                  @search="openPointModal"
                  v-decorator="['location', { rules: [{ required: true, message: '详细位置不能为空' }] }]"
                  placeholder="请输入详细位置"
                >
                  <a-button icon="environment" slot="enterButton">
                  </a-button>
                </a-input-search>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="经度" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['longitude', { rules: [{ required: true, message: '经度不能为空' }] }]"
                         placeholder="请输入经度">
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="纬度" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['latitude', { rules: [{ required: true, message: '维度不能为空' }] }]"
                         placeholder="请输入纬度">
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="负责人姓名" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['userName', { rules: [{ required: true, message: '负责人不能为空' }] }]"
                         placeholder="请输入负责人姓名">
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="联系电话" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['userPhone', {
                  rules: [{ type: 'string', pattern: /^1[0-9]{10}$/, required: true, message: '手机号不合法' }],
                  trigger: 'change',
                }]" placeholder="请输入联系电话">
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="身份证号" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['idcard']"
                         placeholder="请输入身份证号">
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="门牌号" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-decorator="['houseNo']"
                         placeholder="请输入门牌号">
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="车辆负责人"
                           :label-col="labelCol"
                           :wrapper-col="wrapperCol">
                <a-tree-select
                  v-decorator="['dispatcherId',{validateTrigger: ['change'],rules: [{ required: true, message: '车辆负责人不能为空' }]}]"
                  treeDefaultExpandAll
                  treeNodeFilterProp="title"
                  style="width: 100%"
                  :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                  :treeData="orgUserTreeData"
                  placeholder="请选择车辆负责人"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="清运司机"
                           :label-col="labelCol"
                           :wrapper-col="wrapperCol">
                <a-tree-select
                  v-decorator="['driverId', {validateTrigger: ['change'], rules: [{
                    message: '请选择司机',
                  }]}]"
                  treeDefaultExpandAll
                  treeNodeFilterProp="title"
                  style="width: 100%"
                  :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                  :treeData="orgUserTreeData"
                  allowClear
                  placeholder="请选择司机"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-tree-select
                  v-decorator="['orgId', { rules: [{ required: true, message: '所属部门不能为空' }] }]"
                  :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                  :treeData="orgTree"
                  allowClear
                  placeholder="请选择"
                ></a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="备注" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-textarea
                  v-decorator="['matter',]"
                  placeholder="请输入备注"
                  :autoSize="{ minRows: 2 }"
                ></a-textarea>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="type === 'check'">
              <a-form-item label="审核" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-radio-group @change="infoCheckChange"
                               v-decorator="['checkStatus', {initialValue: 'PASS', rules: [{ required: true, message: '请审核档案信息' }] }]"
                >
                  <a-radio value="PASS" style="margin-left: 15px">
                    审核通过
                  </a-radio>
                  <a-radio value="REJECT" style="margin-left: 15px">
                    审核驳回
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-col>
    </a-row>
    <a-modal
      title="地图标记"
      :visible="pointModal"
      width="1200px"
      :maskClosable="false"
      centered
      @ok="handlePointOk"
      @cancel="closePointModal">
      <amap-select-point :type="type" ref="amapSelectPointRef" @pointSuccess="pointSuccess"
                         @pointError="pointError"></amap-select-point>
    </a-modal>
  </a-spin>
</template>

<script>
import { gatherSpotFormVO } from './common/common'
import AmapSelectPoint from './AmapSelectPoint'
import SERVICE_URLS from '@/api/service.url'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'damageReportForm',
  props: {
    type: {
      type: String,
      default: 'edit'
    }
  },
  components: { AmapSelectPoint },
  data () {
    return {
      /*标记图层*/
      pointModal: false,
      point: {},
      /*区域参数*/
      areaDefaultValue: null,
      areaOptions: [],
      userProfileData: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      spinShow: false,
      formItem: gatherSpotFormVO(),
      form: this.$form.createForm(this),
      orgTree: [],
      orgUserTreeData: [],
      spotTypeEnum: spotTypeEnum
    }
  },
  mounted () {
    this.loadDriverList()
    this.getOrgTree()
    this.getCascadeData()
  },
  methods: {
    getCascadeData () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.areaDictionaryApi.cascade,
        noTips: true,
        success: (data) => {
          this.areaOptions = data.body
        }
      })
    },
    typeOnChange (value) {
      if (value === spotTypeEnum.SANGE.value) {
        this.form.setFieldsValue({
          bulk: 2
        })
      } else if (value === spotTypeEnum.DANWENG.value) {
        this.form.setFieldsValue({
          bulk: 0.6
        })
      } else if (value === spotTypeEnum.JIWUCHI.value) {
        this.form.setFieldsValue({
          bulk: 100
        })
      } else {
        this.form.setFieldsValue({
          bulk: 0
        })
      }
    },
    openPointModal () {
      this.pointModal = true
      if (this.type !== 'add') {
        this.$nextTick(() => {
          this.$refs['amapSelectPointRef'].viewLocationInfo(this.formItem.location, this.formItem.longitude, this.formItem.latitude, this.formItem.locationName)
        })
      } else {
        this.$nextTick(() => {
          this.$refs['amapSelectPointRef'].addLocationInfo(this.formItem.areaName)
        })
      }
    },
    handlePointOk () {
      this.$refs['amapSelectPointRef'].submitInfo()
    },
    closePointModal () {
      this.pointModal = false
    },
    pointSuccess (location, lnglat, name) {
      this.pointModal = false
      this.form.setFieldsValue({
        latitude: lnglat.latitude,
        longitude: lnglat.longitude,
        location: location
      })
      this.formItem.latitude = location
      this.formItem.latitude = lnglat.latitude
      this.formItem.longitude = lnglat.longitude
      this.formItem.locationName = name
    },
    pointError () {
      this.pointModal = false
    },
    /*区域选择处理*/
    areaOnChange (options) {
      this.formItem.areaNo = options[2]
      this.formItem.areaName = ''
      for (var i in options) {
        this.setAreaName(this.areaOptions, options[i])
      }
    },
    /*区域名称递归设置*/
    setAreaName (arr, key) {
      for (var i in arr) {
        if (arr[i].value === key) {
          this.formItem.areaName = this.formItem.areaName + arr[i].label
          break
        }
        if (arr[i].children) {
          this.setAreaName(arr[i].children, key)
        }
      }
    },
    getOrgTree () {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        }
      })
    },
    /*获取其他信息*/
    getUserProfile (userName, userPhone) {
      this.$http(this, {
        url: SERVICE_URLS.csgz.gatherSpot.getUserProfile,
        data: {
          userName: userName + '',
          userPhone: userPhone + ''
        },
        noTips: true,
        loading: 'spinShow',
        success: (data) => {
          this.$nextTick(() => {
            this.userProfileData = data.body
          })
        }
      })
    },
    /*删除操作*/
    userProfileDelete (id) {
      const _this = this
      this.$confirm({
        title: '确认删除操作?',
        content: '此数据将会被删除',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          return new Promise((resolve, reject) => {
            _this.$http(_this, {
              url: SERVICE_URLS.csgz.gatherSpot.delete,
              params: {
                id: id
              },
              loading: 'tableLoading',
              noTips: false,
              successTipsContent: '数据删除成功!',
              success: () => {
                _this.getUserProfile()
                resolve()
              }
            })
          }).catch(() => console.log('Oops delete errors!'))
        },
        onCancel () {
        }
      })
    },
    setVOFields (values) {
      if (values.driverId) {
        var driverUserId = values.driverId.split('_')[2]
      }
      if (values.dispatcherId) {
        var dispatcherUserId = values.dispatcherId.split('_')[2]
      }
      this.formItem.dispatcherId = dispatcherUserId
      this.formItem.driverId = driverUserId
      this.formItem.name = values.name
      this.formItem.type = values.type
      this.formItem.positionType = values.positionType
      this.formItem.collectType = values.collectType
      this.formItem.bulk = values.bulk
      this.formItem.latitude = values.latitude
      this.formItem.longitude = values.longitude
      this.formItem.location = values.location
      this.formItem.locationName = values.locationName
      this.formItem.userName = values.userName
      this.formItem.userPhone = values.userPhone
      this.formItem.houseNo = values.houseNo
      this.formItem.idcard = values.idcard
      this.formItem.matter = values.matter
      this.formItem.orgId = values.orgId
      if (this.type === 'check') {
        this.formItem.checkStatus = values.checkStatus
      } else if (this.type === 'add') {
        this.formItem.checkStatus = 'PASS'
      }
      this.formItem.no = values.no
    },
    loadData (id) {
      this.formItem = gatherSpotFormVO()
      this.form.resetFields()
      this.$http(this, {
        url: SERVICE_URLS.csgz.gatherSpot.view,
        params: {
          id: id
        },
        noTips: true,
        loading: 'spinShow',
        success: (data) => {
          this.setFields(data.body)
          setTimeout(() => {
            this.$nextTick(() => {
              /*设定区域默认值*/
              var areaDefault = []
              areaDefault.push(this.formItem.areaNo.substring(0, 2) + '0000')
              areaDefault.push(this.formItem.areaNo.substring(0, 4) + '00')
              areaDefault.push(this.formItem.areaNo.substring(0, 6))
              this.areaDefaultValue = areaDefault
              /*设置默认司机*/
              const driverId = this.getSelectKey(this.orgUserTreeData, data.body.driverId)
              if (driverId) {
                this.form.setFieldsValue({
                  'driverId': driverId
                })
              }
              /*设置默认调度*/
              const dispatcherId = this.getSelectKey(this.orgUserTreeData, data.body.dispatcherId)
              if (dispatcherId) {
                this.form.setFieldsValue({
                  'dispatcherId': dispatcherId
                })
              }
            })
          }, 500)
        }
      })
    },
    setFields (values) {
      Object.assign(this.formItem, values)
      this.form.setFieldsValue({
        no: this.formItem.no,
        name: this.formItem.name,
        type: this.formItem.type,
        collectType: this.formItem.collectType,
        positionType: this.formItem.positionType,
        bulk: this.formItem.bulk,
        latitude: this.formItem.latitude,
        longitude: this.formItem.longitude,
        location: this.formItem.location,
        locationName: this.formItem.locationName,
        userName: this.formItem.userName,
        userPhone: this.formItem.userPhone,
        houseNo: this.formItem.houseNo,
        idcard: this.formItem.idcard,
        matter: this.formItem.matter,
        orgId: this.formItem.orgId
      })
    },
    submitEdit () {
      if (this.formItem.areaNo.length !== 6) {
        this.$message.warning('请选择区域！')
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.csgz.gatherSpot.update,
            data: this.formItem,
            noTips: true,
            params: {
              id: this.formItem.id
            },
            success: (data) => {
              if (this.type === 'check') {
                this.$emit('checkSuccess')
              } else {
                this.$emit('editSuccess', data.body)
              }
            }
          })
        } else {
          if (this.type === 'check') {
            this.$emit('checkError', 'formError')
          } else {
            this.$emit('editError', 'formError')
          }
        }
      })
    },
    loadDriverList () {
      this.$http(this, {
        url: SERVICE_URLS.organization.orgUserTree,
        data: {
          authPrefix: '',
          chooseType: 'RADIO'
        },
        noTips: true,
        success: (data) => {
          this.orgUserTreeData = data.body
        }
      })
    },
    getSelectKey (list, id) {
      var key
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        const tempKey = item.key
        if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
          key = tempKey
          return key
        } else if (item.children.length > 0) {
          key = this.getSelectKey(item.children, id)
        }
        if (key) return key
      }
    },
    submitAdd () {
      if (this.formItem.areaNo.length !== 6) {
        this.$message.warning('请选择区域！')
        return
      }
      this.spinShow = true
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.csgz.gatherSpot.create,
            noTips: true,
            data: this.formItem,
            success: (data) => {
              this.formItem = gatherSpotFormVO()
              this.$emit('addSuccess', data.body)
              this.form.resetFields()
              this.spinShow = false
              var areaDefault = []
              this.areaDefaultValue = areaDefault
            },
            error: (data) => {
              this.$emit('addError')
              this.form.resetFields()
              this.spinShow = false
            }
          })
        } else {
          this.spinShow = false
        }
      })
    },
    infoCheckChange (e) {
      this.formItem.checkStatus = e.target.value
    }
  }
}
</script>

<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="清运司机"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol" v-if="this.type == 'driver' ">
        <a-tree-select
          v-decorator="['driverId']"
          @select="DriverSelect"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="请选择司机"
        />
      </a-form-item>
      <a-form-item label="车辆负责人"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol" v-if="this.type == 'dispatcher' ">
        <a-tree-select
          v-decorator="['dispatcherId']"
          @select="DispatcherSelect"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="车辆负责人"
        />
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { gatherSpotEditFormVO } from './common/common'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'EditForm',
    props: {
      type: {
        type: String,
        default: 'driver'
      },
      idList: {
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: []
      }
    },
    components: {},
    data () {
      return {
        driverId: '',
        dispatcherId: '',
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        spinShow: false,
        formItem: gatherSpotEditFormVO(),
        form: this.$form.createForm(this),
        orgUserTreeData: []
      }
    },
    mounted () {
      this.loadDriverList()
    },
    methods: {
      loadDriverList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: '',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
          }
        })
      },
      DriverSelect (value) {
        this.driverId = value.split('_')[2]
      },
      DispatcherSelect (value) {
        this.dispatcherId = value.split('_')[2]
      },
      setVOFields () {
        this.formItem.driverId = this.driverId
        this.formItem.dispatcherId = this.dispatcherId
        this.formItem.ids = this.idList
      },
      submitEdit () {
        this.form.validateFields((err, values) => {
          if (!err) {
            if (typeof this.setVOFields !== 'undefined' && this.setVOFields instanceof Function) {
              this.setVOFields()
              if (this.type === 'driver') {
                this.$http(this, {
                  url: SERVICE_URLS.csgz.gatherSpot.updateMoreDriver,
                  data: this.formItem,
                  success: (data) => {
                    this.$emit('moreEditSuccess')
                  }
                })
              } else if (this.type === 'dispatcher') {
                this.$http(this, {
                  url: SERVICE_URLS.csgz.gatherSpot.updateMoreDispatcher,
                  data: this.formItem,
                  success: (data) => {
                    this.$emit('moreEditSuccess')
                  }
                })
              }
            }
          } else {
            this.$emit('moreEditError', 'formError')
          }
        })
      }
    }
  }
</script>

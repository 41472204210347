<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">集污点信息</h1>
    </div>
    <div slot="extra">
      <a-button @click="handleDamageReportAdd" icon="up-square" type="primary" style="margin-left: 10px">登记集污点</a-button>
      <a-button icon="export" type="default" style="margin-left: 10px" @click="onClick">导出集污点</a-button>
      <a-modal
        title="导出集污点"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="exportWordSpot"
        @cancel="handleCancel"
      >
        <div style="display: flex;align-items: center;justify-content: center">
          <a-date-picker mode="year"
                         format="YYYY"
                         :value="datePickerValue"
                         @openChange="handleOpenChange1"
                         @panelChange="handlePanelChange1"
                         placeholder="请选择导出年份"
                         style="width: 80%"/>
        </div>

        <!--        <div style="color: red;line-height: 30px;margin-top: 20px">-->
        <!--          请选择时间进行导出集污点清运详情-->
        <!--        </div>-->
      </a-modal>
      <a-button class="m-l-12" icon="import" type="default" @click="importInfo">导入集污点</a-button>
      <a-button class="m-l-12 m-r-24" icon="cloud-download" type="default" @click="downFile">下载导入模板</a-button>
      <!--<a-button class="primary" @click="moreDispatcherEdit" icon="setting" style="margin-left: 7px">批量指派人修改
      </a-button>
      <a-button class="primary" @click="moreDriverEdit" icon="setting" style="margin-left: 7px">批量司机修改
      </a-button>-->
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="编号">
              <a-input v-model="searchParams.no" @change="search" placeholder="请输入编号" style="width: 120px"/>
            </a-form-item>
            <a-form-item label="集污点名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入集污点名称" style="width: 120px"/>
            </a-form-item>
            <a-form-item label="姓名">
              <a-input v-model="searchParams.userName" @change="search" placeholder="请输入农户姓名" style="width: 120px"/>
            </a-form-item>
            <a-form-item label="手机">
              <a-input v-model="searchParams.userPhone" @change="search" placeholder="请输入手机号" style="width: 120px"/>
            </a-form-item>
            <a-form-item label="审核">
              <a-radio-group @change="checkType" v-model="searchParams.checkStatus">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="PASS">通过</a-radio-button>
                <a-radio-button value="REJECT">驳回</a-radio-button>
                <a-radio-button value="UN_CHECK">未审核</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item>
              <area-selection @onclickArea="onclickArea" @search="search"></area-selection>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
              <a :style="{ marginLeft: '10px', fontSize: '12px' }" @click="toggle">
                {{ expand ? '收起' : '展开' }}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-form-item>
            <a-row v-if="expand" style="margin-top: 10px;margin-bottom: 10px;">
              <a-form-item label="集污点类型">
                <a-select default-value=""
                          style="width: 130px"
                          @change="selectType"
                          v-model="searchParams.type">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option :value="spotTypeEnum.DANWENG.value">单翁</a-select-option>
                  <a-select-option :value="spotTypeEnum.JIWUCHI.value">集污池</a-select-option>
                  <a-select-option :value="spotTypeEnum.SANGE.value">三格化粪池</a-select-option>
                  <a-select-option :value="spotTypeEnum.LANI.value">拉泥</a-select-option>
                  <a-select-option :value="spotTypeEnum.GONGCE.value">公厕</a-select-option>
                  <a-select-option :value="spotTypeEnum.OTHER.value">其他</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="微信绑定">
                <a-radio-group @change="bindType" v-model="searchParams.bindType">
                  <a-radio-button value="">全部</a-radio-button>
                  <a-radio-button value="BIND">已绑定</a-radio-button>
                  <a-radio-button value="NOT">未绑定</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="是否清运过">
                <a-radio-group v-model="searchParams.qyFlag">
                  <a-radio-button value="">全部</a-radio-button>
                  <a-radio-button value="true">是</a-radio-button>
                  <a-radio-button value="false">否</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-row>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :customRow="handleView"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        :scroll="{ x: 1600 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="name" slot-scope="text, record">
          <div v-if="record.collectType==='WECHAT'">
            <a-icon style="color:#2ba245" type="wechat" theme="filled"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;{{ record.name }}
          </div>
          <div v-else-if="record.collectType==='REGISTER'">
            <a-icon style="color:#2b97ff" type="phone" theme="filled"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;{{ record.name }}
          </div>
          <div v-else-if="record.collectType==='IMPORT'">
            <a-icon style="color:#5007FF" type="import"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;
            {{ record.name }}
          </div>
        </template>
        <template slot="type" slot-scope="text, record">
          <span v-if="record.type===spotTypeEnum.DANWENG.value">
            单翁
          </span>
          <span v-if="record.type===spotTypeEnum.JIWUCHI.value">
            集污池
          </span>
          <span v-if="record.type===spotTypeEnum.SANGE.value">
            三格化粪池
          </span>
          <span v-if="record.type===spotTypeEnum.LANI.value">
            拉泥
          </span>
          <span v-if="record.type===spotTypeEnum.GONGCE.value">
            公厕
          </span>
          <span v-if="record.type===spotTypeEnum.OTHER.value">
            其他
          </span>
        </template>
        <template slot="checkStatus" slot-scope="text, record">
          <a-tag v-if="record.checkStatus==='PASS'" color="#00CD66">
            通过
          </a-tag>
          <a-button v-if="record.checkStatus==='UN_CHECK'" size="small" type="link"
                    @click.stop="handleCheck(record)">
            审核
          </a-button>
          <a-tag v-if="record.checkStatus==='REJECT'" color="#ff0000">
            驳回
          </a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <!--  <a @click.stop="handleBinding(record.id)">绑定</a>
            <a-divider type="vertical"/>-->
          <a @click.stop="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleDelete(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      :width="1000"
      title="修改集污点信息"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal">
      <spot-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></spot-form>
    </a-modal>
    <a-modal
      :width="550"
      :title="modalTitle"
      :visible="moreEditModal"
      @ok="handleMoreEditOk"
      @cancel="closeMoreEditModal">
      <edit-form ref="moreEditRef" :type="userType" :idList="tempIdList" @moreEditSuccess="moreEditSuccess"
                 @moreEdit="moreEditError"></edit-form>
    </a-modal>
    <a-modal
      :width="1000"
      title="添加集污点信息"
      :visible="addModal"
      :maskClosable="true"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal">
      <spot-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></spot-form>
    </a-modal>
    <a-modal
      :width="1400"
      title="审核集污点信息"
      :visible="checkModal"
      :maskClosable="true"
      centered
      @ok="handleCheckOk"
      @cancel="closeCheckModal">
      <spot-form ref="checkRef" type="check" @checkSuccess="checkSuccess" @addError="checkError"></spot-form>
    </a-modal>
    <a-drawer
      title="集污点详细信息"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="1200px"
      :visible="detailDrawer"
      :mask="false">
      <spot-detail ref="viewRef"></spot-detail>
    </a-drawer>
    <a-modal title="导入" v-model="importModel" :footer="null" :maskClosable="false" width="480px">
      <import-form :import-url="importUrl"></import-form>
    </a-modal>
    <a-modal title="绑定集污点" v-model="bindingModel"
             @ok="handleBindingOk"
             @cancel="closeBindingModal" :maskClosable="false" width="480px">
      <monitoringPointTree @selectedTreeNode="selectedTreeNode"></monitoringPointTree>
    </a-modal>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import { gatherspotColumns } from './common/common'
import PageLayout from '@/components/page/PageLayout'
import SpotForm from './Form'
import SpotDetail from './Detail'
import ImportForm from '../../base/ImportForm'
import entityCRUD from '../../common/mixins/entityCRUD'
import EditForm from './EditIdForm'
import moment from 'moment'
import monitoringPointTree from '../../../components/monitoringPointTree/List'
import AreaSelection from '../statistics/loophistory/AreaSelection'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'GatherSpotList',
  components: { EditForm, SpotForm, PageLayout, ImportForm, SpotDetail, monitoringPointTree, AreaSelection },
  mixins: [entityCRUD],
  data () {
    return {
      //导入
      importModel: false,
      importUrl: SERVICE_URLS.csgz.gatherSpot.import,
      editLoading: false,
      entityBaseUrl: SERVICE_URLS.csgz.gatherSpot,
      detailDrawer: false,
      moreEditModal: false,
      addModal: false,
      dispatchModal: false,
      feedbackModal: false,
      confirmModal: false,
      checkModal: false,
      expand: false,
      selectedRowKeys: [],
      tempIdList: [],
      userType: '',
      modalTitle: '',
      searchParams: {
        page: '0',
        size: '10',
        direction: '',
        order: '',
        no: '',
        name: '',
        type: '',
        bindType: '',
        checkStatus: '',
        userPhone: '',
        userName: '',
        exportDate: moment().format('YYYY'),
        qyFlag: '',
        codes: ''
      },
      initColumns: gatherspotColumns(),
      tableData: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
      },
      valueStyle: {
        color: '',
        textAlign: 'center'
      },
      datePickerValue: moment().format('YYYY'),
      visible: false,
      confirmLoading: false,
      bindingModel: false,
      selectSpotInfo: {},
      spotTypeEnum: spotTypeEnum
    }
  },
  created () {
    this.search()
  },
  computed: {
    currentUser () {
      return this.$store.getters.currentUser
    }
  },
  methods: {
    moment,
    toggle () {
      this.expand = !this.expand
      console.info(this.expand, '<<<<')
    },
    handleCheck (record) {
      this.checkModal = true
      this.$nextTick(() => {
        this.$refs['checkRef'].loadData(record.id)
        this.$refs['checkRef'].getUserProfile(record.userName, record.userPhone)
      })
    },
    downFile () {
      window.open('/template/集污点导入模板.xlsx')
    },
    handleMoreEditOk () {
      if (this.$refs['moreEditRef']) {
        if (
          typeof this.$refs['moreEditRef'].submitEdit !== 'undefined' &&
          this.$refs['moreEditRef'].submitEdit instanceof Function
        ) {
          this.$refs['moreEditRef'].submitEdit()
        } else {
          console.error('Form子组件内部未定义submitEdit方法')
        }
      } else {
        console.error('Form子组件的editRef未指定')
      }
    },
    closeMoreEditModal () {
      if (this.$refs['moreEditRef']) {
        if (this.$refs['moreEditRef'].formItem.images || this.$refs['moreEditRef'].formItem.files) {
          this.$refs.editRef.formItem.imageIds = []
          this.$refs.editRef.formItem.images = []
          this.$refs.editRef.formItem.fileIds = []
          this.$refs.editRef.formItem.files = []
        } else {
          // console.log('该modal中没有图片、文件')
        }
      }
      this.moreEditModal = false
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.tempIdList = []
      this.selectedRowKeys = selectedRowKeys
      for (let item of selectedRows) {
        this.tempIdList.push(item.id)
      }
    },
    moreDispatcherEdit () {
      if (this.tempIdList.length > 0) {
        this.moreEditModal = true
        this.userType = 'dispatcher'
        this.modalTitle = '批量指派人修改'
      } else {
        this.$message.success('未选择集污点！')
      }
    },
    moreDriverEdit () {
      if (this.tempIdList.length > 0) {
        this.moreEditModal = true
        this.userType = 'driver'
        this.modalTitle = '批量司机修改'
      } else {
        this.$message.success('未选择集污点！')
      }

    },
    moreEditSuccess () {
      this.moreEditModal = false
      this.$message.success('批量修改成功！')
    },
    moreEditError () {
      this.moreEditModal = false
      this.$message.success('批量修改失败！')
    },
    importInfo () {
      this.importModel = true
    },
    selectType () {
      this.search()
    },
    bindType () {
      this.search()
    },
    checkType () {
      this.search()
    },
    deleteSucceed () {
      this.search()
    },
    detailClose () {
      this.detailDrawer = false
      //重置报抽查询条件按照编号查询
      this.$refs.viewRef.resetData()
    },
    handleView (record) {
      return {
        on: {
          click: () => {
            this.detailDrawer = true
            this.$nextTick(() => {
              //重置报抽查询条件按照编号查询
              this.$refs.viewRef.resetData()
              this.$refs.viewRef.loadData(record.id)
            })
          }
        }
      }
    },
    handleDamageReportAdd () {
      this.addModal = true
    },
    //上报
    handleAddOk () {
      this.$refs['addRef'].submitAdd()
    },
    handleCheckOk () {
      this.$refs['checkRef'].submitEdit()
    },
    closeAddModal () {
      this.addModal = false
    },
    closeCheckModal () {
      this.checkModal = false
    },
    addSuccess () {
      this.addModal = false
      this.$message.success('登记成功！')
      this.search()
    },
    checkSuccess () {
      this.checkModal = false
      this.search()
    },
    checkError () {
      this.checkModal = false
    },
    addError () {
      this.$message.warning('登记失败！')
      this.addModal = false
    },
    tableChange (pagination, filters, sorter) {
      this.searchParams.page = pagination.current - 1
      this.searchParams.size = pagination.pageSize
      this.search()
    },
    search () {
      this.$http(this, {
        url: this.entityBaseUrl.search,
        noTips: true,
        loading: 'tableLoading',
        data: this.searchParams,
        success: (data) => {
          this.tableData = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = data.body.number + 1
          this.pagination.pageSize = data.body.size
          this.searchParams.size = data.body.size
          this.searchParams.page = data.body.number
        }
      })
    },
    handleOpenChange1 () {

    },
    handlePanelChange1 (value, mode) {
      this.datePickerValue = value
      this.searchParams.exportDate = moment(this.datePickerValue).format('YYYY')
    },
    onClick () {
      this.visible = true
    },
    handleCancel () {
      this.visible = false
    },
    exportWordSpot () {
      if (this.searchParams.exportDate === '') {
        this.$message.warning('请选择导出年份')
        return
      }
      this.confirmLoading = true
      const fileName = '清运农户档案.doc'
      this.$http(this, {
        url: SERVICE_URLS.csgz.reportRecord.export_spot_statistics_info,
        data: this.searchParams,
        noTips: true,
        export: true,
        noLoadingBar: true,
        responseType: 'blob',
        success: (data) => {
          const blob = new Blob([data], { type: 'application/vnd.ms-doc' })
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
          this.confirmLoading = false
        }
      })
    },
    handleBindingOk () {
      if (!this.selectSpotInfo.monitorPointNo || !this.selectSpotInfo.monitorPointName || this.selectSpotInfo.monitorPointNo === '' || this.selectSpotInfo.monitorPointName === '') {
        this.$message.warning('请选择绑定的监测点')
      } else {
        this.updateBingSpot()
        this.bindingModel = false
      }
    },
    closeBindingModal () {
      this.bindingModel = false
    },
    handleBinding (id) {
      this.selectSpotInfo.id = id
      this.$http(this, {
        url: SERVICE_URLS.csgz.gatherSpot.view,
        params: {
          id: id
        },
        noTips: true,
        loading: 'spinShow',
        success: (data) => {
          Object.assign(this.selectSpotInfo, data.body)
        }
      })
      this.bindingModel = true
    },
    selectedTreeNode (key, node) {
      if (key.length > 0) {
        this.selectSpotInfo.monitorPointNo = node.dataRef.no
        this.selectSpotInfo.monitorPointName = node.dataRef.title
      } else {
        this.selectSpotInfo.monitorPointNo = ''
        this.selectSpotInfo.monitorPointName = ''
      }
    },
    updateBingSpot () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.gatherSpot.update,
        data: this.selectSpotInfo,
        noTips: true,
        params: {
          id: this.selectSpotInfo.id
        },
        success: (data) => {
          this.$message.success('绑定成功')
          this.search()
        }
      })
    },
    onclickArea (areaCodes) {
      this.searchParams.codes = areaCodes.join(',')
    }
  }
}
</script>
